import React from "react"
import { graphql } from "gatsby"
import { Disclosure, Transition } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/solid"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import GoogleAdsense from "../components/GoogleAdsense"

const FaqPage = ({ data }) => {
  const faqs = data?.allContentfulFrequentlyAskedQuestion?.nodes ?? []

  return (
    <Layout>
      <Seo title="các câu hỏi thường gặp" />
      <h1 className="font-medium text-3xl sm:text-4xl pb-4">
        câu hỏi thường gặp
      </h1>
      {faqs.map((faq, index) => (
        <Disclosure key={`faq-${index + 1}`}>
          {({ open }) => (
            <div>
              <Disclosure.Button className="flex justify-between items-center w-full px-6 py-2 font-medium text-left text-gray-900 bg-gray-200 rounded-lg hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span className="pr-2">{faq?.question}</span>
                <ChevronUpIcon
                  className={`transition-transform duration-100 ${
                    open ? "transform rotate-180" : ""
                  } w-5 h-full text-gray-500`}
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Disclosure.Panel className="px-6 py-4 text-gray-600">
                  <div
                    className="whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html: faq?.answerTextNode?.childMarkdownRemark?.html,
                    }}
                  />
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>
      ))}
      <GoogleAdsense
        client="ca-pub-1804168523618722"
        slot="3767743010"
        className="google-ads-footer"
      />
    </Layout>
  )
}

export default FaqPage

export const query = graphql`
  {
    allContentfulFrequentlyAskedQuestion(
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        question
        answerTextNode: childContentfulFrequentlyAskedQuestionAnswerTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
